/* Base styles */
body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(to right, #333, #333);
  color: #ffffff;
}

/* Container for the card */
.card {
  background: #ffffff;
  color: #333;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px 30px;
  max-width: 400px;
  text-align: center;
  animation: fadeIn 1.5s ease-out;
}

/* Heading style */
h1 {
  font-size: 1.8rem;
  margin-bottom: 10px;
  color: #ff7e5f;
}

/* Subheading */
p {
  font-size: 1.1rem;
  margin-bottom: 20px;
  line-height: 1.5;
}

/* Highlighted text (for time) */
.time {
  font-weight: bold;
  color: #ff7e5f;
  font-size: 1.2rem;
  display: block;
  margin: 10px 0;
}

/* Animation for fade-in effect */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}